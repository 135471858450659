import React from "react";

export const Navigation = (props) => {
const contstyle = {
width: '100%', 
margin: 'auto', 
};
const mstyle = {
width: '100%', 
margin: '0',
padding: '0',
};
const tstyle = {
margin: '0 0 0 2px',
padding: '0 0 0 2px',
};
const navstyle = {
width: '100%', 
};
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={mstyle}>

    <table style={navstyle}>
      <tr>
        <td style={tstyle}>
             <a href="#page-top">
               <img src="/img/logo2.png"></img>
             </a>
        </td>
        <td align="right" style={navstyle}>
      <div className="container" style={contstyle}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>


        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >

          <ul className="nav navbar-nav navbar-right">
            <li>
             <a href="#page-top" className="page-scroll">
                Home
             </a>
            </li>
            <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            <li>
              <a href="/go" className="page-scroll">
                Login
              </a>
            </li>

          </ul>
        </div>
      </div>
     </td>
    </tr>
    </table>
    </nav>
  );
};
