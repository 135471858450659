import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Features</h2>
          <p></p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col">
                  {" "}
                  <a href="#services">
                  <i className={d.icon}></i>
                  </a>
                  <h2>{d.title}</h2>
                  <p>&nbsp;</p>
                  <h1>{d.text}</h1>
                  <p>&nbsp;</p>
                <a href="#services"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Forward
                </a>{" "}

                </div>
              ))
            : "Loading..."}

        </div>
      </div>
    </div>
  );
};
